import React, {useState, forwardRef, useImperativeHandle} from 'react';
import Toast from './Toast';
import './Toast.css'

const ToastContainer = forwardRef((props, ref) => {
    const [toasts, setToasts] = useState([]);

    // Internal function to add a new toast
    const showToast = (type, message, duration = 3000) => {
        const id = Date.now(); // A unique ID
        const newToast = {id, type, message};
        setToasts((prevToasts) => [...prevToasts, newToast]);

        // Automatically remove the toast after the given duration
        setTimeout(() => {
            setToasts((prevToasts) => prevToasts.filter((t) => t.id !== id));
        }, duration);
    };

    // Allow the parent to call showToast
    useImperativeHandle(ref, () => ({
        showToast,
    }));

    return (
        <div className="toast-container">
            {toasts.map((toast) => (
                <Toast
                    key={toast.id}
                    type={toast.type}
                    message={toast.message}
                    onClose={() =>
                        setToasts((prevToasts) => prevToasts.filter((t) => t.id !== toast.id))
                    }
                />
            ))}
        </div>
    );
});

export default ToastContainer;
