import React from 'react';

const Toast = ({type, message, onClose}) => {
    // Choose a background color based on the toast type
    const getBackgroundColor = () => {
        switch (type) {
            case 'success':
                return '#4caf50'; // green
            case 'info':
                return '#2196f3'; // blue
            case 'error':
                return '#f44336'; // red
            case 'warning':
                return '#ff9800'; // orange
            default:
                return '#333';    // fallback (dark gray)
        }
    };

    return (
        <div className="toast" style={{backgroundColor: getBackgroundColor()}}>
            <span>{message}</span>
            <button className="toast-close-btn" onClick={onClose}>
                &times;
            </button>
        </div>
    );
};

export default Toast;
