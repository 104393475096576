import React, {useEffect, useState} from 'react';
import './Policy.css';

const PolicyDynamic = () => {
    const [policyData, setPolicyData] = useState([]);

    useEffect(() => {
        fetch(`${process.env.PUBLIC_URL}/Data/policy.json`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch policy data');
                }
                return response.json();
            })
            .then(data => setPolicyData(data))
            .catch(error => console.error('Error loading policy:', error));
    }, []);

    const renderElement = (element, index) => {
        switch (element.element) {
            case 'title':
                return (
                    <div key={index}>
                        <h1>{element.title}</h1>
                        {element.content && <p>{element.content}</p>}
                    </div>
                );
            case 'subtitle':
                return (
                    <div key={index}>
                        <h2>{element.title}</h2>
                        {element.content && <p>{element.content}</p>}
                    </div>
                );
            case 'text':
                return (
                    <div key={index}>
                        <h3>{element.title}</h3>
                        {element.content && <p>{element.content}</p>}
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="policy">
            {policyData.map((element, index) => renderElement(element, index))}
        </div>
    );
};

export default PolicyDynamic;
