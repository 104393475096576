import React, {useRef} from 'react';
import emailjs from 'emailjs-com';
import styles from './Contact.module.css';
import {useToast} from "../Utils/ToastProvider";

const Contact = () => {
    // Create a reference for the form
    const formRef = useRef();
    const {showSuccessToast, showErrorToast} = useToast();

    // Function to handle sending the email
    const sendEmail = (e) => {
        e.preventDefault();

        // Replace with your actual EmailJS credentials
        emailjs.sendForm(
            'service_0k3sl6a',
            'template_3ee0c6c',
            formRef.current,
            'KIeWIHSdzN_BCoSMb'
        )
            .then((result) => {
                showSuccessToast('Email sent!');
                e.target.reset(); // Optionally reset the form
            })
            .catch((error) => {
                showErrorToast('Something went wrong!');
            });
    };

    return (
        <div className={styles.contactForm}>
            <h1 className={styles.heading}>Contact Us</h1>
            <form ref={formRef} onSubmit={sendEmail} className={styles.form}>

                <label htmlFor="name" className={styles.label}>Name:</label>
                <input
                    type="text"
                    id="name"
                    name="user_name"
                    className={styles.inputField}
                    required
                />

                <label htmlFor="email" className={styles.label}>Email:</label>
                <input
                    type="email"
                    id="email"
                    name="user_email"
                    className={styles.inputField}
                    required
                />

                <label htmlFor="subject" className={styles.label}>Subject:</label>
                <input
                    type="text"
                    id="subject"
                    name="subject"
                    className={styles.inputField}
                    required
                />

                <label htmlFor="message" className={styles.label}>Message:</label>
                <textarea
                    id="message"
                    name="message"
                    className={styles.textarea}
                    required
                />

                <button type="submit" className={styles.submitButton}>Send</button>
            </form>
        </div>
    );
};

export default Contact;
