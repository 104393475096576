import React, {createContext, useRef, useContext} from 'react';
import ToastContainer from './ToastContainer';

// Create a context for the toast functions
const ToastContext = createContext();

export const ToastProvider = ({children}) => {
    const toastRef = useRef();

    // Base function to show a toast using the ref
    const showToast = (type, message, duration = 3000) => {
        if (toastRef.current) {
            toastRef.current.showToast(type, message, duration);
        }
    };

    // Helper functions for different toast types
    const showSuccessToast = (message, duration) => showToast('success', message, duration);
    const showErrorToast = (message, duration) => showToast('error', message, duration);
    const showInfoToast = (message, duration) => showToast('info', message, duration);
    const showWarningToast = (message, duration) => showToast('warning', message, duration);

    return (
        <ToastContext.Provider
            value={{showToast, showSuccessToast, showErrorToast, showInfoToast, showWarningToast}}
        >
            {children}
            {/* Attach the ref to ToastContainer */}
            <ToastContainer ref={toastRef}/>
        </ToastContext.Provider>
    );
};

// Custom hook for easy access to the toast functions
export const useToast = () => useContext(ToastContext);
